import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { servicio } from '../clases/servicios';

@Injectable({
  providedIn: 'root'
})
export class NuestrosServiciosService {
  apiUrl = 'https://raw.githubusercontent.com/cyberProyects/jsonRomano/master/areas.json';
  serviciosUrl = 'https://raw.githubusercontent.com/cyberProyects/jsonRomano/master/jsonDeServicios2';
  constructor(private http: HttpClient) {}
  /** Areas */
  getAllAreas (){
    return this.http.get<any[]>(this.apiUrl);
  }
  /** Servicios */
  getAllServicios(){
    return this.http.get<servicio[]>(this.serviciosUrl);
  }
}
