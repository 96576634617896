import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Email } from '../clases/email';
import { EmailInterface } from '../interfaces/email-interface';


@Injectable({
  providedIn: 'root'
})
export class EmailService {
  url:string;
  prueba: any;

  constructor(private http: HttpClient,private global:GlobalService) {
    this.url = global.urlAPI+'/enviarCorreo';
    //this.url = 'http://localhost:3000/emails/send';
  }
  postEmail(email:EmailInterface){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': this.url,
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
    return this.http.post(this.url, email, {headers});
  }

}
