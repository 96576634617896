import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nota-analisis-sanguineo',
  templateUrl: './nota-analisis-sanguineo.component.html',
  styleUrls: ['./nota-analisis-sanguineo.component.css']
})
export class NotaAnalisisSanguineoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

}
