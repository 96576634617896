import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  redirigir(vista){
    $("#botonCerrarModal").click();
    if(vista == "Inicio"){
      $("#vistaInicio").click();
    }
    else if(vista == "Nosotros"){
      $("#vistaNosotros").click();
    }
    else if(vista == "Areas"){
      $("#vistaAreas").click();
    }
    else if(vista == "Servicios"){
      $("#vistaServicios").click();
    }
    else if(vista == "Contacto"){
      $("#vistaContacto").click();
    }
    else if(vista == "Noticias"){
      $("#vistaNoticias").click();
    }

  
  }
}
