import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  @Input() public imagen: string;
  public ruta:string;
  constructor() { }


  ngOnInit() {
    if (this.imagen=="bannermuestra"){
      this.ruta="assets/img/bannermuestra.jpg";
    }

    if (this.imagen=="bannerservicios"){
      this.ruta="assets/img/bannerservicios.jpg";
    }

    if (this.imagen=="bannerareas"){
      this.ruta="assets/img/bannerareas.jpg";
    }
  }

}
