import { Component, OnInit } from '@angular/core';
import {NgForm, FormGroup, FormBuilder, Validators} from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';
import { EmailInterface } from 'src/app/interfaces/email-interface';
import * as $ from 'jquery';



@Component({
  selector: 'cardcontacto',
  templateUrl: './cardcontacto.component.html',
  styleUrls: ['./cardcontacto.component.css'],
  providers: []
})
export class CardcontactoComponent implements OnInit {
  formulario: FormGroup;
  constructor(private emailService:EmailService,private formBuilder:FormBuilder) { }

  ngOnInit() {
    this.formulario = this.formBuilder.group({
      email: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      telefono: ['', Validators.required],
      contenido: ['', Validators.required]
    });



  }


  limpiarFormulario(){
    $("#nombre").val('');
    $("#apellido").val('');
    $("#email").val('');
    $("#contenido").val('');
    $("#telefono").val('');
    $("#alertMensajeEnviado").removeClass(['invisible']);

  }

  enviarEmail(){
    //SI es invalido retorno vacio y corta la ejecucion
    if(this.formulario.invalid){
      return;
    }
    //Desabilito el boton para que no se pueda mandar otro seguir clickeando mientras se manda el emial
    $("#botonEnviarFormulario").prop( "disabled", true );
    $("#loading").removeClass('invisible');
    let email:EmailInterface = this.formulario.value;

    this.emailService.postEmail(email).subscribe(data  => {
      // Si todo es correcto se limpia el formulario
       this.limpiarFormulario();
       $("#loading").addClass('invisible');
       //Vuelvo a hablilitar el mensaje
       $("#botonEnviarFormulario").prop( "disabled", false );
      },

      error  => {

       console.log("Error", error);

      });


  }

}
