import { Component, OnInit } from '@angular/core';
import { estudio } from '../../clases/estudio';
import { ApiService } from '../../services/jsonservice.service';
import * as $ from 'jquery'
import { ServiciotomaMuestraService } from 'src/app/services/serviciotoma-muestra.service';


@Component({
  selector: 'cardenfermedad',
  templateUrl: './cardenfermedad.component.html',
  styleUrls: ['./cardenfermedad.component.css']
})
export class CardenfermedadComponent implements OnInit {

  estudiomostrar:any = {};
  preparaciones:any[] = [];
  estudios: any[];
  query:string;

  constructor(private tomamuestra: ServiciotomaMuestraService) { }

  ngOnInit() {
    this.estudios = this.tomamuestra.data
  }

  mostrarPreparacion(estudio){
    $("#botonModal").click();
    this.estudiomostrar.titulo = estudio.titulo;
    this.estudiomostrar.descripcion = estudio.descripcion;
    this.preparaciones = [];
    
    if(estudio.item1 != null){
      this.preparaciones.push(estudio.item1);
    }
    
    if(estudio.item2 != null){
      this.preparaciones.push(estudio.item2);
    }
    if(estudio.item3 != null){
      this.preparaciones.push(estudio.item3);
    }
    if(estudio.item4 != null){
      this.preparaciones.push(estudio.item4);
    }
    if(estudio.item5 != null){
      this.preparaciones.push(estudio.item5);
    }
    if(estudio.item6 != null){
      this.preparaciones.push(estudio.item6);
    }
    if(estudio.item7 != null){
      this.preparaciones.push(estudio.item7);
    }
    if(estudio.item8 != null){
      this.preparaciones.push(estudio.item8);
    }
  
  
  
  }


}
