import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {


  noticias = [
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAtiV0bi5aZ/', img:'../../../assets/noticias/noticia38.webp', titulo: 'Sacar tu turno con nosotros es muy fácil.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAoAxyAox3M/', img:'../../../assets/noticias/noticia37.webp', titulo: 'Si sos afiliado de O.S.M.A.T.A podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAWMWSIi7Zr/', img:'../../../assets/noticias/noticia36.webp', titulo: '¿Sabias que podes descargar tus resultados desde nuestra página web?'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DAMEFWLRFzm/', img:'../../../assets/noticias/noticia35.webp', titulo: 'Día de la Primavera.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/DALhs4BJT5m/', img:'../../../assets/noticias/noticia34.webp', titulo: 'Día Mundial de la Sanidad.'},
    {link:'https://www.instagram.com/reel/DAEjcaxq8Pc/', img: '../../../assets/noticias/noticia33.webp', titulo: 'Estos son nuestros horarios de atención.' },
    {link:'https://www.instagram.com/reel/C_8BjDdBfk7/', img:'../../../assets/noticias/noticia32.webp', titulo: 'Día Mundial del Linfoma.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_xxwQ_ocVf/', img:'../../../assets/noticias/noticia31.webp', titulo: '¡Saca tu turno fácil y rápido por WhatsApp!'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_qUj5QIfBf/', img:'../../../assets/noticias/noticia30.webp', titulo: '8 de septiembre día mundial de la fibrosis quística.'},
    {link:'https://fb.watch/uraVWlBWmq/', img:'../../../assets/noticias/noticia29.webp', titulo: 'Sabemos que para sacar tu registro de conducir te piden un certificado del grupo y el factor Rh, nosotros te lo hacemos y tenes el resultado en el ACTO.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_QW3i1CO7L/', img:'../../../assets/noticias/noticia28.webp', titulo: 'Si sos afiliado de UNION PERSONAL podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C_BXdCdvgJv/', img:'../../../assets/noticias/noticia27.webp', titulo: '¿Aun no te hiciste tus chequeos anuales?'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-2psJPvtYq/', img:'../../../assets/noticias/noticia26.webp', titulo: 'Día Nacional de Lucha contra el Síndrome Urémico Hemolítico.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-z3zdHKEz6/', img:'../../../assets/noticias/noticia22.png', titulo: 'Feliz día de las infancias.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-xUyM2tylw/', img:'../../../assets/noticias/noticia25.webp', titulo: 'Paso a la inmortalidad del general José de San Martín.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-nqCilolz6/', img:'../../../assets/noticias/noticia24.webp', titulo: 'Si sos afiliado de SWISS MEDICAL podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-azvjVNk85/', img:'../../../assets/noticias/noticia23.webp', titulo: 'Te dejamos algunos tips para que el momento de la extracción sea más comodo y fácil para vos.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C-GAY51Bgav/', img:'../../../assets/noticias/noticia22.webp', titulo: 'Contamos con un servicio exclusivo donde hacemos extracciones en la comodidad de tu hogar.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9-jNW5hvyz/', img:'../../../assets/noticias/noticia21.webp', titulo: 'Día Mundial Contra La Hepatitis.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C92V4G8udOy/', img:'../../../assets/noticias/noticia20.webp', titulo: 'Preparación para prueba PSA.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9iMDe_PCME/', img:'../../../assets/noticias/noticia19.webp', titulo: 'Si sos afiliado de OSDE podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9SLt_IoxHO/', img:'../../../assets/noticias/noticia18.webp', titulo: 'Contamos con nuestro garage exclusivo donde hacemos extracciones en tu vehículo.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9MtwR0hyJ7/', img:'../../../assets/noticias/noticia17.webp', titulo: 'Día de la Independencia Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C9KX-wst970/', img:'../../../assets/noticias/noticia16.webp', titulo: 'El 9 de Julio el laboratorio permanecerá cerrado por el feriado conmerorativo al día de la Independencia Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C89lhqKpeBm/', img:'../../../assets/noticias/noticia15.webp', titulo: 'Si sos afiliado de PAMI/IOMA podes atenderte con nosotros.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8r99GlPjoy/', img:'../../../assets/noticias/noticia14.webp', titulo: 'El día 4 de Junio, reacreditamos el laboratorio.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8cDtOUBvnn/', img:'../../../assets/noticias/noticia13.webp', titulo: 'Día de la Bandera Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8YKQKmB22H/', img:'../../../assets/noticias/noticia12.webp', titulo: 'En Laboratorio Romano podes abonar con cualquiera de estos Medios de Pago.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8XA_NxIfQI/', img:'../../../assets/noticias/noticia11.webp'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8UYv29h6jN/', img:'../../../assets/noticias/noticia10.webp', titulo: 'Día de la Libertad Latinoamericana.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8R84lRC0hX/', img:'../../../assets/noticias/noticias09.webp', titulo: 'Feliz día del padre.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8PE7-vgjqC/', img:'../../../assets/noticias/noticia09.webp', titulo: 'Día del bioquímico.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8NDfmKNr5c/', img:'../../../assets/noticias/noticia08.webp'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C8E7T5MCFAo/', img:'../../../assets/noticias/noticia07.webp', titulo: 'Día Mundial del Cáncer de Próstata'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C8CgQ_-p_Yb/', img:'../../../assets/noticias/noticia06.webp', titulo: 'Día de la cruz roja Argentina.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C74KapUM9Lf/', img:'../../../assets/noticias/noticia05.webp', titulo: 'Día mundial de los trasplantados.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C71a53UsnTx/', img:'../../../assets/noticias/noticia04.webp', titulo: 'Día internacional del medio ambiente'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C7onOlXsPDZ/', img:'../../../assets/noticias/noticia03.webp', titulo: 'Día Mundial sin Tabaco.'},
    {titulo:'Día Nacional de la Donación de Organos.', link:'https://www.instagram.com/laboratorioromanozte/p/C7mTp2wpYb8/', img:'../../../assets/noticias/noticia02.webp'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C7mEu-vJcb7/', img:'../../../assets/noticias/noticia01.webp', titulo: 'Día mundial de la esclerosis multiple.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C7g5d4YMIIQ/', img:'../../../assets/noticias/noticia00.webp', titulo: 'Día Internacional de Acción por la Salud de las Mujeres.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C7ZRVkvROS5/', img:'../../../assets/noticias/noticia99.webp', titulo: 'Día de la revolución de mayo.'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C7WbabVtcx7/?img_index=1', img:'../../../assets/noticias/noticia98.webp', titulo: 'Día Internacional de la Epilepsia'},
    {link:'https://www.instagram.com/laboratorioromanozte/p/C7Uey88tRZY/', img:'../../../assets/noticias/noticia97.webp', titulo: 'Informamos que el día 25 de Mayo, nuestro laboratorio estará cerrado.'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C7EkUG_pwUm/', img:'../../../assets/noticias/noticia96.webp'},
    {link:'https://www.instagram.com/laboratorioromanozte/reel/C7EkKhWMdZG/', img:'../../../assets/noticias/noticia95.webp', titulo: 'Día Mundial de la Hipertensión Arterial.'},
    {link:'https://www.facebook.com/photo/?fbid=949821733816932&set=a.476867271112383', img:'../../../assets/noticias/noticia94.webp', titulo: 'Día internacional de la enfermera.'},
    {link:'https://www.facebook.com/watch/?v=1037005741125005', img:'../../../assets/noticias/noticia93.webp', titulo: 'Día Mundial del Lupus.'},
];



  constructor() { }

  ngOnInit() {
  }

}
